<template>
  <b-card-group columns>
    <b-card>
      <b-link :to="{ name: 'dev-tools-selections' }">
        <b-card-title>Selections</b-card-title>
        <b-badge>Delete Selections</b-badge>
      </b-link>
    </b-card>
    <b-card>
      <b-link :to="{ name: 'dev-tools-invoices' }">
        <b-card-title>Invoices</b-card-title>
        <b-badge>Create Missing Invoices</b-badge>
      </b-link>
    </b-card>
  </b-card-group>
</template>

<script>
export default {
  name: 'Tools'
}
</script>

<style scoped>

</style>
